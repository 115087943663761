var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "app flex-row align-items-center"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('b-row', {
    staticClass: "justify-content-center"
  }, [_c('b-col', {
    attrs: {
      "md": "5"
    }
  }, [_c('b-card-group', [_c('b-card', {
    staticClass: "p-4",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-body', [_c('b-form', [_c('h1', [_vm._v("Login")]), _c('p', {
    staticClass: "text-muted"
  }, [_vm._v(" Sign In to your account ")]), _c('b-input-group', {
    staticClass: "mb-3"
  }, [_c('b-input-group-prepend', [_c('b-input-group-text', [_c('i', {
    staticClass: "icon-user"
  })])], 1), _c('b-form-input', {
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "placeholder": "User name",
      "autocomplete": "username email"
    },
    model: {
      value: _vm.username,
      callback: function callback($$v) {
        _vm.username = $$v;
      },
      expression: "username"
    }
  })], 1), _c('b-input-group', {
    staticClass: "mb-4"
  }, [_c('b-input-group-prepend', [_c('b-input-group-text', [_c('i', {
    staticClass: "icon-lock"
  })])], 1), _c('b-form-input', {
    staticClass: "form-control",
    attrs: {
      "type": "password",
      "placeholder": "Password",
      "autocomplete": "current-password"
    },
    model: {
      value: _vm.password,
      callback: function callback($$v) {
        _vm.password = $$v;
      },
      expression: "password"
    }
  })], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-button', {
    staticClass: "px-4",
    attrs: {
      "variant": "primary",
      "disabled": _vm.isLoading
    },
    on: {
      "click": _vm.login
    }
  }, [!_vm.isLoading ? _c('font-awesome-icon', {
    attrs: {
      "icon": "sign-in-alt"
    }
  }) : _vm._e(), _vm.isLoading ? _c('b-spinner', {
    attrs: {
      "small": "",
      "type": "grow"
    }
  }) : _vm._e(), _vm._v(" Login ")], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('b-modal', {
    ref: "login-modal",
    attrs: {
      "centered": "",
      "no-fade": "",
      "ok-only": "",
      "title": "Error"
    }
  }, [_vm._v(" " + _vm._s(_vm.modal.message) + " ")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }