<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="5">
          <b-card-group>
            <b-card no-body class="p-4">
              <b-card-body>
                <b-form>
                  <h1>Login</h1>
                  <p class="text-muted">
                    Sign In to your account
                  </p>
                  <b-input-group class="mb-3">
                    <b-input-group-prepend>
                      <b-input-group-text
                        ><i class="icon-user"
                      /></b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input
                      type="text"
                      class="form-control"
                      v-model="username"
                      placeholder="User name"
                      autocomplete="username email"
                    />
                  </b-input-group>
                  <b-input-group class="mb-4">
                    <b-input-group-prepend>
                      <b-input-group-text
                        ><i class="icon-lock"
                      /></b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input
                      type="password"
                      class="form-control"
                      v-model="password"
                      placeholder="Password"
                      autocomplete="current-password"
                    />
                  </b-input-group>
                  <b-row>
                    <b-col cols="6">
                      <b-button
                        variant="primary"
                        class="px-4"
                        @click="login"
                        :disabled="isLoading"
                      >
                        <font-awesome-icon
                          v-if="!isLoading"
                          icon="sign-in-alt"
                        />
                        <b-spinner v-if="isLoading" small type="grow" />
                        Login
                      </b-button>
                    </b-col>
                    <!--
                    <b-col cols="6" class="text-right">
                      <b-button variant="link" class="px-0"  @click="forgotPassword">Forgot password?</b-button>
                    </b-col>
                    -->
                  </b-row>
                </b-form>
              </b-card-body>
            </b-card>
            <!--
            <b-card no-body class="text-white bg-primary py-5 d-md-down-none" style="width:44%">
              <b-card-body class="text-center">
                <div>
                  <h2>Sign up</h2>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                  <b-button variant="primary" class="active mt-3">Register Now!</b-button>
                </div>
              </b-card-body>
            </b-card>
            -->
          </b-card-group>
        </b-col>
      </b-row>
    </div>
    <b-modal ref="login-modal" centered no-fade ok-only title="Error">
      {{ modal.message }}
    </b-modal>
  </div>
</template>

<script>
import { AUTH_REQUEST } from "../store/actions/auth";

export default {
  name: "Login",
  components: {},
  data: function() {
    return {
      isLoading: false,
      username: "",
      password: "",
      modal: {
        message: ""
      }
    };
  },
  mounted() {},
  methods: {
    login: function() {
      const { username, password } = this;

      this.isLoading = true;

      this.$store
        .dispatch(AUTH_REQUEST, {
          username,
          password
        })
        .then(() => {
          //this.isLoading = false
          let notAuthRequest = this.$store.getters["router/getNotAuthRequest"];

          if (notAuthRequest) {
            this.$store.dispatch("router/deleteNotAuthRequest");

            this.$router.push(notAuthRequest);
          } else {
            this.$router.push("/");
          }
        })
        .catch(ex => {
          console.error(ex);
          this.isLoading = false;

          if (ex.error) this.modal.message = ex.error;
          if (ex.password) this.modal.message = ex.password[0];
          if (ex.username) this.modal.message = ex.username[0];

          this.$refs["login-modal"].show();
        });
    }
  }
};
</script>
